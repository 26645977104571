/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.sm\:bg-default-background {
    background-repeat: no-repeat;
    background-size: cover;
}

.DayPickerInput > input {
    width: 100%;
    max-width: 400px;
    margin-top: 4px;
    padding: 5px;
    border-radius: 3px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgb(209, 213, 219);
}

.DayPicker {
    font-size: 14px;
}

.tr-default:nth-child(odd) {
    @apply bg-gray-100  hover:bg-yellow-50;
}
